import React, { useState, createContext, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';

/**
 * @param {string} str A space-separated list of monitor ids (integers)
 * @returns {number[]} an array containing each integer id from the string
 */
const parseMonitorIds = (str) => {
  return str.split(' ').map(chunk => Number.parseInt(chunk));
};

const MonitorIds = Object.freeze({
  DeliveryPro: parseMonitorIds(process.env.REACT_APP_DPMONITOR_ID),
  SortMarshal: parseMonitorIds(process.env.REACT_APP_SMMONITOR_ID),
  Redash: parseMonitorIds(process.env.REACT_APP_REDASHMONITOR_ID),
  PointSort: parseMonitorIds(process.env.REACT_APP_PSMONITOR_ID),
  IdeasPortal: parseMonitorIds(process.env.REACT_APP_IPMONITOR_ID),
  Vista: parseMonitorIds(process.env.REACT_APP_VISTAMONITOR_ID)
});

/**
 * Tries to fetch all monitors from Uptime Robot. Returns any error that occurs.
 * @param {string} apiKey
 * @returns {[{id: number, status: number}[]?, Error?]} An array containing monitors from Uptime Robot, and an error.
 */
const fetchMonitorData = async () => {
  const axiosConfig = {
    'Access-Control-Allow-Origin': '*',
    'Accept': '*/*',
  };

  // TODO: UptimeRobot implements pagination. Limit is set to 50, and we're at 19 (2023-01-30) so we're OK for now
  const uptimeRobotApi = process.env.REACT_APP_UPTIME_API_URL + 'v2/getMonitors?api_key=' + process.env.REACT_APP_UPTIME_ALL_API_KEY;

  return await axios
    .post(uptimeRobotApi, axiosConfig)
    .then(res => [res.data.monitors, undefined])
    .catch(err => [undefined, err]);
};

/**
 * Computes the overall status of a system given a list of its related monitor IDs
 * and a list of all monitors. Note that `true` will still be returned if any given
 * monitor ID is not found in `allMonitors`.
 * @param {number[]} monitorIds The IDs of the system
 * @param {{id: number, status: number}[]} allMonitors All monitors from Uptime Robot
 * @returns {boolean} `true` if all system monitors are Operational, otherwise `false`.
 */
const getAggregateStatus = (monitorIds, allMonitors) => {
  const relatedMonitors = allMonitors.filter((monitor) => monitorIds.includes(monitor.id));

  const allMonitorsOperational = relatedMonitors.every(monitor => monitor.status === 2);
  return allMonitorsOperational ? Status.Operational : Status.Interruption;
};

const Status = Object.freeze({
  Operational: 0,
  Interruption: 1,
  ComingSoon: 2
});

const StatusContext = createContext({
  deliveryProStatus: Status.Operational,
  sortMarshalStatus: Status.Operational,
  redashStatus: Status.Operational,
  pointSortStatus: Status.Operational,
  ideasPortalStatus: Status.Operational,
  puroPointStatus: Status.Operational,
  vistaStatus: Status.Operational,
  error: undefined
});

const StatusProvider = (props) => {

  const [monitors, setMonitors] = useState([]);
  const [error, setError] = useState(undefined);

  const updateMonitors = useCallback(() => {
    fetchMonitorData()
      .then(([monitors, error]) => {
        setMonitors(monitors ?? []);
        setError(error);
      });
  }, [setMonitors, setError]);

  useEffect(() => {
    // Initial fetch
    updateMonitors();

    // Set up polling and return tear-down function
    const pollInterval = setInterval(updateMonitors, process.env.REACT_APP_INTERVAL);
    return () => clearInterval(pollInterval);
  }, [updateMonitors]);

  // Computes the status of each project each time the monitors change
  const contextValue = useMemo(() => ({
    deliveryProStatus: getAggregateStatus(MonitorIds.DeliveryPro, monitors),
    sortMarshalStatus: getAggregateStatus(MonitorIds.SortMarshal, monitors),
    redashStatus: getAggregateStatus(MonitorIds.Redash, monitors),
    pointSortStatus: getAggregateStatus(MonitorIds.PointSort, monitors),
    ideasPortalStatus: getAggregateStatus(MonitorIds.IdeasPortal, monitors),
    puroPointStatus: Status.ComingSoon, // TODO: implement monitoring
    vistaStatus: getAggregateStatus(MonitorIds.Vista, monitors),
    error
  }), [monitors, error]);

  return (
    <StatusContext.Provider value={contextValue}>
      {props.children}
    </StatusContext.Provider>
  );
};

export default StatusProvider;
export { StatusContext, Status };