import React, { useContext } from 'react';
import StatusCard from '../StatusCard/StatusCard';
import { StatusContext } from '../../Context/StatusContext';
import { Container, Card } from 'react-bootstrap';
import SortMarshalLogo from '../../assets/projects/sortmarshal.png';
import PuroPointLogo from '../../assets/projects/puropoint.png';
import DeliveryProLogo from '../../assets/projects/deliverypro.jpg';
import RedashLogo from '../../assets/projects/redash.png';
import PointSortLogo from '../../assets/projects/pointsort.png';
import IdeasPortalLogo from '../../assets/projects/ideasportal.png';
import vistaLogo from '../../assets/projects/vista_dark.png';

import './PDLStatus.css';

const openInNewTab = (url) => {
  window.open(url, '_blank', 'noopener, noreferrer');
};

const StatusCards = () => {
  const {
    deliveryProStatus,
    sortMarshalStatus,
    redashStatus,
    pointSortStatus,
    ideasPortalStatus,
    puroPointStatus,
    vistaStatus,
    error
  } = useContext(StatusContext);

  const monitors = [
    { name: "Delivery Pro", status: deliveryProStatus, logo: DeliveryProLogo, monitorPage: "https://dp-uptimestatus.purolab.net/" },
    { name: "PuroPoint", status: puroPointStatus, logo: PuroPointLogo, monitorPage: undefined }, // TODO: this needs to be connected
    { name: "SortMarshal", status: sortMarshalStatus, logo: SortMarshalLogo, monitorPage: "https://sm-uptimestatus.purolab.net/" },
    { name: "Redash", status: redashStatus, logo: RedashLogo, monitorPage: "https://redash-uptimestatus.purolab.net/" },
    { name: "PointSort", status: pointSortStatus, logo: PointSortLogo, monitorPage: "https://pointsort-uptimestatus.purolab.net/" },
    { name: "Idea Portal", status: ideasPortalStatus, logo: IdeasPortalLogo, monitorPage: "https://stats.uptimerobot.com/lmr57hOPYo" },
    { name: "Vista", status: vistaStatus, logo: vistaLogo, monitorPage: "https://vista-uptimestatus.purolab.net"},
  ];

  if (error) {
    return (
      <Card className="text-center bg-warning" style={{ borderRadius: 0 }}>
        <Card.Body>An error occured while fetching monitor statuses, please try again in a few minutes.</Card.Body>
      </Card>
    );
  };

  return (
    <Container>
      <div className="status-cards">
        {monitors
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(({ name, status, logo, monitorPage }) => (
            <StatusCard
              key={`${name}-${status}`}
              projectName={name}
              status={status}
              logo={logo}
              onClick={monitorPage && (() => openInNewTab(monitorPage))} />
          ))}
      </div>
    </Container>
  );
};

export default StatusCards;